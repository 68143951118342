<template>
  <div>

    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>
    <div class="content_section">
      <div class="content">
        <sub-title>미니게임</sub-title>
        <leisure-game-comp-links></leisure-game-comp-links>
        <leisure-comp-game-moa-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_GAMEMOA"></leisure-comp-game-moa-game-links>
        <leisure-comp-bet365-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_BET365"></leisure-comp-bet365-game-links>
        <leisure-comp-star-sadali-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_STAR_SADALI"></leisure-comp-star-sadali-game-links>
        <leisure-comp-e-o-s-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_EOS"></leisure-comp-e-o-s-game-links>
        <leisure-comp-h-s-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_HS"></leisure-comp-h-s-game-links>
        <leisure-comp-tgames365-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_TOKENGAME"></leisure-comp-tgames365-game-links>
        <leisure-comp-named-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_NAMED"></leisure-comp-named-game-links>
        <leisure-comp-crown-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_CROWN"></leisure-comp-crown-game-links>
        <leisure-comp-sky-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_SKY"></leisure-comp-sky-game-links>
        <leisure-comp-next-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_NEXT"></leisure-comp-next-game-links>
        <leisure-comp-pam-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_PAMGAME"></leisure-comp-pam-game-links>
        <leisure-comp-mgm-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_MGM"></leisure-comp-mgm-game-links>
        <leisure-comp-lotus-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_LOTUS"></leisure-comp-lotus-game-links>
        <leisure-comp-sure-man-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_SUREMAN"></leisure-comp-sure-man-game-links>
        <leisure-comp-donghang-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_DONGHANG"></leisure-comp-donghang-game-links>
        <leisure-comp-ntry-game-links
            v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_NTRY"></leisure-comp-ntry-game-links>

        <router-view></router-view>

      </div>
    </div>
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>
    <scroll-top-comp></scroll-top-comp>

    <!--푸트메뉴-->
    <foot-comp></foot-comp>
  </div>

</template>

<script>
import RightBarComp from "../../components/RightBarComp";
import SubTitle from "../../components/SubTitle";
import LeftBarComp from "../../components/LeftBarComp";
import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
import sportsConst from "../../common/sportsConst";
import leisureConst from "../../common/leisureConst";
import {getOnBetLeisureGames} from "../../network/leisureRequest";
import ScrollTopComp from "../../components/ScrollTopComp";
import TopbarComp from "../../components/TopbarComp";
import FootComp from "../../components/FootComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import LeisureGameLinks from "./LeisureGameLinks";
import LeisureGameCompLinks from "./LeisureCompLinks";
import LeisureCompGameLinks from "./LeisureCompNtryGameLinks.vue";
import LeisureCompCrownGameLinks from "./LeisureCompCrownGameLinks";
import LeisureCompLotusGameLinks from "./LeisureCompLotusGameLinks";
import {postionMixin} from "../../common/mixin";
import LeisureCompSkyGameLinks from "./LeisureCompSkyGameLinks";
import LeisureCompBet365GameLinks from "./LeisureCompBet365GameLinks";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import LeisureCompStarSadaliGameLinks from "./LeisureCompStarSadaliGameLinks";
import LeisureCompMgmGameLinks from "./LeisureCompMgmGameLinks";
import LeisureCompNamedGameLinks from "./LeisureCompNamedGameLinks";
import LeisureCompTgames365GameLinks from "./LeisureCompTgames365GameLinks";
import LeisureCompEOSGameLinks from "./LeisureCompEOSGameLinks";
import LeisureCompHSGameLinks from "./LeisureCompHSGameLinks";
import LeisureCompGameMoaGameLinks from "./LeisureCompGameMoaGameLinks";
import LeisureCompNextGameLinks from "./LeisureCompNextGameLinks";
import LeisureCompPamGameLinks from "./LeisureCompPamGameLinks";
import LeisureCompSureManGameLinks from "./LeisureCompSureManGameLinks";
import LeisureCompNtryGameLinks from "@/views/leisuregame/LeisureCompNtryGameLinks.vue";
import LeisureCompDonghangGameLinks from "@/views/leisuregame/LeisureCompDonghangGameLinks.vue";

export default {
  name: "LeisureGame",
  mixins: [postionMixin],
  components: {
    LeisureCompDonghangGameLinks,
    LeisureCompNtryGameLinks,
    LeisureCompSureManGameLinks,
    LeisureCompPamGameLinks,
    LeisureCompNextGameLinks,
    LeisureCompGameMoaGameLinks,
    LeisureCompHSGameLinks,
    LeisureCompEOSGameLinks,
    LeisureCompTgames365GameLinks,
    LeisureCompNamedGameLinks,
    LeisureCompMgmGameLinks,
    LeisureCompStarSadaliGameLinks,
    RightButtonsComp,
    UserInfoComp,
    LeisureCompBet365GameLinks,
    LeisureCompSkyGameLinks,
    LeisureCompLotusGameLinks,
    LeisureCompCrownGameLinks,
    LeisureCompGameLinks,
    LeisureGameCompLinks,
    LeisureGameLinks,
    SportsLeftBarComp,
    RightBarBannerComp,
    FootComp, TopbarComp, ScrollTopComp, LeisureLeftComp, LeftBarComp, SubTitle, RightBarComp
  },
  data() {
    return {
      leisureConst: leisureConst,
      position: "미니게임",
    }
  },
  created() {
  }

}
</script>

<style scoped>
@import url("../../assets/css/base.css");
@import url("../../assets/css/common.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/media.css");
@import url("../../assets/css/sports.css");
@import url("../../assets/css/minigame.css");
</style>